<template>
  <div class="idx-left-box1">
    <div class="idx-Lbox-1">社区管理队伍概况</div>
    <div class="idx-Lbox-2">
      <div class="idx-middle-3">
        <div class="shequ-1 shequ-title">年龄架构</div>
        <div class="shequ-1">
          <div class="shequ-1a">20-30岁</div>
          <div class="shequ-1b">{{ getdatavalue(140) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">30-40岁</div>
          <div class="shequ-1b">{{ getdatavalue(141) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">40-50岁</div>
          <div class="shequ-1b">{{ getdatavalue(142) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">50-60岁</div>
          <div class="shequ-1b">{{ getdatavalue(143) }}</div>
        </div>
      </div>
      <div class="idx-middle-3">
        <div class="shequ-1 shequ-title">工作年限</div>
        <div class="shequ-1">
          <div class="shequ-1a">5年以内</div>
          <div class="shequ-1b">{{ getdatavalue(144) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">6-10年</div>
          <div class="shequ-1b">{{ getdatavalue(145) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">10-20年</div>
          <div class="shequ-1b">{{ getdatavalue(146) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">20年以上</div>
          <div class="shequ-1b">{{ getdatavalue(147) }}</div>
        </div>
      </div>
      <div class="idx-middle-3">
        <div class="shequ-1 shequ-title">学历分布</div>
        <div class="shequ-1">
          <div class="shequ-1a">大专</div>
          <div class="shequ-1b">{{ getdatavalue(148) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">本科</div>
          <div class="shequ-1b">{{ getdatavalue(149) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">研究生</div>
          <div class="shequ-1b">{{ getdatavalue(150) }}</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">其它</div>
          <div class="shequ-1b">{{ getdatavalue(151) }}</div>
        </div>
      </div>
      <div class="idx-middle-4">
        <div class="shequ-1 shequ-title">男女比例</div>
        <div class="shequ-1 shequ-1a2">
          <div class="shequ-1a">男性</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">{{ getdatavalue(152) }}</div>
        </div>
        <div class="shequ-1 shequ-1a2">
          <div class="shequ-1a">女性</div>
        </div>
        <div class="shequ-1">
          <div class="shequ-1a">{{ getdatavalue(153) }}</div>
        </div>
        <!-- <div class="idx-middle-4box">
          <div class="shequ-1">
            <div class="shequ-1a">男性</div>
            <div class="shequ-1b">39人</div>
          </div>
          <div class="shequ-1">
            <div class="shequ-1a">女性</div>
            <div class="shequ-1b">18人</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sqdw",
        dataTyp: "sqdw_ryfb",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },
  methods: {
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-left-box1 {
  width: 100%;
  height: 51vh;
  //   border: 1px solid thistle;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffd901;
    padding-left: 15.8vw;
    padding-top: 3.7vh;
  }
  .idx-Lbox-2 {
    width: 95%;
    height: 17vh;
    // border: 1px solid rgba(86, 111, 236, 1);
    margin: 0 auto;
    position: relative;
    margin-top: 6vh;
    margin-left: 0.55vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .idx-middle-3 {
      width: 30%;
      height: 15vh;
      overflow: hidden;
      border: 1px solid rgba(86, 111, 236, 1);
      margin: 0 auto;
      margin-top: 0vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: none;
      .shequ-1 {
        width: 100%;
        height: 3vh;
        line-height: 3vh;
        border-bottom: 1px solid rgba(86, 111, 236, 1);
        font-size: 0.7vw;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .shequ-1a {
          width: 60%;
          text-align: center;
          border-right: 1px solid rgba(86, 111, 236, 1);
        }
        .shequ-1b {
          width: 40%;
          text-align: center;
        }
      }
      .shequ-title {
        width: 100%;
        text-align: left;
        padding-left: -12vw;
        display: inline-block;
        padding-left: 1.4vw;
      }
    }
    .idx-middle-4 {
      width: 15% !important;
      height: 15vh;
      overflow: hidden;
      border: 1px solid rgba(86, 111, 236, 1);
      margin: 0 auto;
      margin-top: 0vh;
      font-size: 0.7vw;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .shequ-1 {
        width: 100%;
        height: 3vh;
        line-height: 3vh;
        border-bottom: 1px solid rgba(86, 111, 236, 1);
        font-size: 0.7vw;
        color: #fff;
        text-align: center;
      }
      .shequ-1a2 {
        border-bottom: none !important;
      }
      .shequ-title {
        width: 100%;
        height: 3vh;
        text-align: center;
      }
      .idx-middle-4box {
        // width: 10% !important;
        height: 12vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
