<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img class="back-ground-image" src="@/assets/image/idx-shequ-new.png" alt="" />
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <!-- Left -->
      <div class="sq-Left">
        <!-- 街道驻社区团队（人） -->
        <JieDao @dialog_click="dialog_click"></JieDao>
        <!-- 街道驻社区团队（人）弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        <!-- 驻点工作 -->
        <ZhudianWk @dialog_click="dialog_click"></ZhudianWk>
        <!-- 驻点工作弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        <!-- 窗口政务服务事项 -->
        <ShequFw @dialog_click="dialog_click"></ShequFw>
        <!-- 窗口政务服务事项弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        <!-- 街道驻社区团队（人） -->
        <!-- <ZhiyuanHuodong></ZhiyuanHuodong> -->
        <!-- 获奖情况 -->
        <!-- <huoJiang></huoJiang> -->
        <!-- 折线图、柱状图 -->
        <div class="sq-left-bottom">
          <zheXianTu></zheXianTu>
        </div>
      </div>
      
      <!-- Right -->
      <div class="sq-Right">
        <div class="sq-Right-top">
          <div class="sq-Right-top-L">
            <!-- 社区管理队伍概况 -->
            <ShequGl @dialog_click="dialog_click"></ShequGl>
            <!-- 社区管理队伍概况弹框 -->
            <Dialog
              :dialog="edit_dialog"
              :dialogTitle="dialogTitle"
              :dataList="dataList"
            >
            </Dialog>
          </div>
          <div class="sq-Right-top-R">
            <div class="sq-Right-top-R1">
              <!-- 驻点工作 -->
              <!-- <ZhudianWk></ZhudianWk> -->
              <!-- 获奖情况 -->
              <huoJiang></huoJiang>
            </div>
            <div class="sq-Right-top-R2">
              <!-- 承接省、市、街道试点任务 -->
              <!-- <ShidianRw></ShidianRw> -->
              <!-- 目标承诺制工作任务 -->
              <muBiao></muBiao>
            </div>
          </div>
        </div>
        <!-- <div class="sq-Right-bottom"> -->
          <!-- <div class="sq-Right-bottom-L"> -->
            <!-- 社区服务管理 -->
            <!-- <ShequFw></ShequFw> -->
          <!-- </div> -->
          <!-- <div class="sq-Right-bottom-R"> -->
            <!-- 社区服务管理 队伍概况-->
            <!-- <RenyuanFb></RenyuanFb> -->
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/dialog/dialog.vue";
import Date from "@/views/commonComponents/date.vue";
import JieDao from "@/views/community/components/jieDao.vue";
import ZhiyuanHuodong from "@/views/community/components/zhiyuanHuodong.vue";
import huoJiang from "@/views/community/components/huoJiang.vue";
import muBiao from "@/views/community/components/muBiao.vue";
import ShequGl from "@/views/community/components/shequGl.vue";
import ZhudianWk from "@/views/community/components/zhudianWk.vue";
import ShidianRw from "@/views/community/components/shidianRw.vue";
import ShequFw from "@/views/community/components/shequFw.vue";
import RenyuanFb from "@/views/community/components/renyuanFb.vue";
import zheXianTu from "@/views/community/components/zheXianTu.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";

export default {
  components: {
    Date,
    JieDao,
    ZhiyuanHuodong,
    huoJiang,
    muBiao,
    ShequGl,
    ZhudianWk,
    ShidianRw,
    ShequFw,
    RenyuanFb,
    rightNav,
    zheXianTu,
    Dialog
  },
  data() {
    return {
      edit_dialog: false,
      dialogTitle: '',
      dataList: [],  // 传递数据
    };
  },
  created() {},
  methods: {
    // 弹框出现
    dialog_click(t,e) {
      if(!this.existSystem()){
        return false;
      }
      console.log("实际数据", e);
      this.dialogTitle = t,
      this.dataList = e;
      this.edit_dialog = true;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  .time-box {
    width: 25vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 7vh;
    right: 3vw;
    z-index: 99;
  }
  // Left
  .sq-Left {
    width: 35.6vw;
    height: calc(100% - 10vh);
    margin-right: 1.5vw;
    // border: 1px solid tan;
    float: left;
    position: relative;
    .sq-left-bottom{
      margin-top: 1vh;
      width: 177%;
      height: 36vh;
      // border: 1px solid red;
      box-sizing: border-box;
    }
  }
  .sq-Right {
    width: 61vw;
    height: calc(100% - 10vh);
    margin-right: 0vw;
    // border: 1px solid teal;
    float: left;
    position: relative;
    .sq-Right-top {
      width: 100%;
      height: 55vh;
      // border: 1px solid tan;
      .sq-Right-top-L {
        width: 42%;
        height: 55vh;
        // border: 1px solid thistle;
        float: left;
      }
      .sq-Right-top-R {
        width: 56%;
        height: 55vh;
        // border: 1px solid tomato;
        float: right;
        .sq-Right-top-R1 {
          width: 100%;
          height: 40vh;
          // border: 1px solid tomato;
        }
        .sq-Right-top-R2 {
          width: 100%;
          height: 48vh;
          overflow: hidden;
          // border: 1px solid tomato;
        }
      }
    }
    .sq-Right-bottom {
      width: 100%;
      height: 55vh;
      // border: 1px solid tan;
      .sq-Right-bottom-L {
        width: 31%;
        height: 32vh;
        // border: 1px solid tan;
        float: left;
      }
      .sq-Right-bottom-R {
        width: 66%;
        height: 32vh;
        // border: 1px solid tan;
        float: right;
      }
    }
  }
}
</style>
