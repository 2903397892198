<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">窗口政务服务事项</div>
    <div class="idx-middle-2">
      <div class="sq-text">
        <p @click="go">
          窗口政务服务事项 <span class="sq-s1">{{ getdatavalue(128) }}</span>  项，
          本月收集<span class="sq-s1">{{ getdatavalue(129) }}</span> 项，
          办结<span class="sq-s1">{{ getdatavalue(130) }}</span> 件，
          累计<span class="sq-s1">{{ getdatavalue(131) }}</span> 件，
        </p>
      </div>
    </div>
    <!-- <div class="idx-middle-3">
      <div class="shequ-1 shequ-title">累计办理</div>
      <div class="shequ-1">
        <div class="shequ-1a">居住证办理</div>
        <div class="shequ-1b">{{ getdatavalue(132) }}张</div>
      </div>
      <div class="shequ-1">
        <div class="shequ-1a">老年人IC卡办理</div>
        <div class="shequ-1b">{{ getdatavalue(133) }}张</div>
      </div>
      <div class="shequ-1">
        <div class="shequ-1a">社保卡办理</div>
        <div class="shequ-1b">{{ getdatavalue(134) }}张</div>
      </div>
      <div class="shequ-1">
        <div class="shequ-1a">生育险办理</div>
        <div class="shequ-1b">{{ getdatavalue(135) }}人</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sqdw",
        dataTyp: "sqdw_sqfwgl",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
      // console.log(response);
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "窗口政务服务事项", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 12vh;
  overflow: hidden;
  // border: 1px solid tan;

  .idx-middle-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.8vw;
    padding-top: 4.5vh;
  }
  .idx-middle-2 {
    width: 100%;
    height: 10vh;
    overflow: hidden;
    // border: 1px solid teal;
    margin: 0 auto;
    margin-top: 2vh;
    .sq-text {
      width: 100%;
      height: 10vh;
      // border: 1px solid thistle;
      text-align: justify;
      color: #fff;
      font-size: 0.8vw;
      line-height: 3vh;
      p {
        // letter-spacing: 0.1vw;
        padding-left: 2.8vw;
      }
      .sq-s1 {
        font-family: hy;
        color: #ffd901;
        font-weight: bold;
        font-style: italic;
        font-size: 1vw;
        padding: 0 0.3vw;
      }
    }
  }
  .idx-middle-3 {
    width: 77%;
    height: 15vh;
    overflow: hidden;
    border: 1px solid rgba(86, 111, 236, 1);
    margin: 0 auto;
    margin-top: 0vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .shequ-1 {
      width: 100%;
      height: 3vh;
      line-height: 3vh;
      border-bottom: 1px solid rgba(86, 111, 236, 1);
      font-size: 0.7vw;
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      .shequ-1a {
        width: 60%;
        text-align: center;
        border-right: 1px solid rgba(86, 111, 236, 1);
      }
      .shequ-1b {
        width: 40%;
        text-align: center;
      }
    }
    .shequ-title {
      width: 100%;
      text-align: left;
      padding-left: -12vw;
      display: inline-block;
      padding-left: 3vw;
    }
  }
}
</style>
