<template>
  <div class="idx-left-box1">
    <router-link tag="div" class="idx-Lbox-1" to="/jiagouTu">社区管理队伍概况</router-link>
    <div class="idx-Lbox-2">
      <ul @click="go">
        <li>
          <span>{{ getdatavalue(119) }}</span>
          <span>党委书记</span>
        </li>
        <li>
          <span>{{ getdatavalue(120) }}</span>
          <span>党委副书记</span>
        </li>
        <li>
          <span>{{ getdatavalue(121) }}</span>
          <span>党委委员</span>
        </li>
        <li>
          <span>{{ getdatavalue(122) }}</span>
          <span>管理岗位</span>
        </li>
        <li>
          <span>{{ getdatavalue(123) }}</span>
          <span>网格员</span>
        </li>
        <li>
          <span>{{ getdatavalue(124) }}</span>
          <span>治安队</span>
        </li>
        <li>
          <span>{{ getdatavalue(125) }}</span>
          <span>后勤岗位</span>
        </li>
        <li>
          <span>{{ getdatavalue(126) }}</span>
          <span>外聘人员</span>
        </li>
        <li>
          <span>{{ getdatavalue(127) }}</span>
          <span>购买服务</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sqdw",
        dataTyp: "sqdw_sqgldwgk",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },
  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "社区管理队伍概况", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-left-box1 {
  width: 100%;
  height: 51vh;
  //   border: 1px solid thistle;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    padding-left: 9.2vw;
    padding-top: 8vh;
    font-weight: bold;
    color: #FFD901;
    cursor: pointer;
  }
  .idx-Lbox-2 {
    width: 100%;
    height: 35vh;
    // border: 1px solid tomato;
    margin: 0 auto;
    position: relative;
    margin-top: 6vh;
    ul li {
      width: 25%;
      height: 7.5vh;
      line-height: 3vh;
      //   border: 1px solid tan;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      margin-left: 1.6vw;
      margin-bottom: 1.6vw;
      background: rgba(1, 104, 155, 1);
      span {
        font-size: 1vw;
        &:first-child {
          font-family: hy;
          font-weight: bold;
          font-style: italic;
        }
      }
    }
  }
}
</style>
