<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">驻点工作</div>
    <div class="idx-middle-2" @click="go">
      <div class="zdW-1">
        <div class="zdW-1a">
          <span>{{ getdatavalue(136) }}</span>
          户
        </div>
        <span>上门走访群众</span>
      </div>
      <div class="zdW-1">
        <div class="zdW-1a">
          <span>{{ getdatavalue(137) }}</span>
          条
        </div>
        <span>收集群众反映各问题</span>
      </div>
      <div class="zdW-1">
        <div class="zdW-1a">
          <span>{{ getdatavalue(138) }}</span>
        </div>
        <span>已处理</span>
      </div>
      <div class="zdW-1">
        <div class="zdW-1a">
          <span>{{ getdatavalue(139) }}</span>
        </div>
        <span>处理率</span>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sqdw",
        dataTyp: "sqdw_zdgz",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },

  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "驻点工作", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 13vh;
  overflow: hidden;
  //   border: 1px solid tan;
  .idx-middle-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.9vw;
    padding-top: 1.9vh;
  }
  .idx-middle-2 {
    width: 90%;
    height: 14vh;
    overflow: hidden;
    // border: 1px solid #409eff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    margin: 0 auto;
    margin-left: 3vw;
    .zdW-1 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      color: #eeab1a;
      line-height: 2.5vh;
      font-size: 0.9vw;
      .zdW-1a span {
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffd901;
        font-size: 1.2vw;
      }
    }
  }
}
</style>
