<template>
  <div class="idx-left-box1" @click="go">
    <div class="idx-Lbox-1">街道驻社区团队（人）</div>
    <div class="idx-Lbox-2">
      <div class="idx-Lbox-2a">000000000</div>
      <div class="idx-Lbox-2b">{{ getdatavalue(112) }}</div>
    </div>
    <div class="idx-Lbox-3">
      <div class="idx-Lbox-3L">
        <div class="idx-Lbox-3L-top">养老服务机构</div>
        <div class="idx-Lbox-3L-bt">{{ getdatavalue(113) }}</div>
      </div>
      <div class="idx-Lbox-3R">
        <div class="idx-Lbox-3R-top">志愿者</div>
        <div class="idx-Lbox-3R-bt">{{ getdatavalue(114) }}</div>
      </div>
    </div>
    <!--  -->
    <div class="sq-text">
      {{ getdatavalue(281) }}年开展活动次数 <span class="sq-s1">{{
        getdatavalue(115)
      }}</span> 次,<br/>
      当月开展活动次数 <span class="sq-s1">{{ getdatavalue(116) }}</span> 次,<br/>
      当月服务时长 <span class="sq-s1">{{ getdatavalue(117) }}</span> 小时，<br/>
      全年服务 <span class="sq-s1">{{ getdatavalue(118) }}</span> 小时
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
import { getDicts } from "@/api/data";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sqdw",
        dataTyp: "sqdw_jdzsqtd",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },
  methods: {
    go() {
      let arr = this.dataOptions.map((item) => {
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName,
        };
      });
      this.$emit("dialog_click", "街道驻社区团队(人)", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-left-box1 {
  width: 100%;
  height: 26vh;
  //   border: 1px solid tan;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 3.2vw;
    padding-top: 2vh;
  }
  .idx-Lbox-2 {
    width: 87%;
    height: 7vh;
    line-height: 10vh;
    // border: 1px solid tomato;
    margin: 0 auto;
    position: relative;
    .idx-Lbox-2a {
      font-family: "hy";
      font-weight: bold;
      font-style: italic;
      font-size: 2vw;
      color: #ffd901;
      opacity: 0.1;
      letter-spacing: 1.255vw;
      height: 7vh;
      padding-left: .8vw;
      line-height: 8vh;
      position: relative;
      top: -.4vh;
      z-index: 0;
    }
    .idx-Lbox-2b {
      position: absolute;
      top: 0.1vh;
      left: -9.2vw;
      font-family: "hy";
      font-style: italic;
      font-weight: bold;
      font-size: 2vw;
      color: #ffd901;
      opacity: 1;
      letter-spacing: 1.2vw;
      padding-left: 1.5vw;
      height: 7vh;
      line-height: 7vh;
      text-align: right;
      width: 100%;
      z-index: 999;
    }
  }
  .idx-Lbox-3 {
    width: 88%;
    height: 11vh;
    /* line-height: 7vh; */
    // border: 1px solid tan;
    font-size: 2vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .idx-Lbox-3L {
      margin-right: 5vw;
      margin-left: 2vw;
      .idx-Lbox-3L-top {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        padding-bottom: 1vh;
      }
      .idx-Lbox-3L-bt {
        font-size: 1.7vw;
        font-family: zcoolqingkehuangyouti;
        font-weight: bold;
        font-style: italic;
        color: #eeab1a;
        font-family: "hy";
      }
    }
    .idx-Lbox-3R {
      margin-right: 8vw;
      margin-left: 2vw;
      .idx-Lbox-3R-top {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        padding-bottom: 1vh;
      }
      .idx-Lbox-3R-bt {
        font-size: 1.7vw;
        font-family: zcoolqingkehuangyouti;
        font-weight: bold;
        font-style: italic;
        color: #eeab1a;
        font-family: "hy";
      }
    }
  }
  .sq-text {
    width: 10vw;
    height: 10vh;
    // border: 1px solid thistle;
    position: absolute;
    top: 11vh;
    right: 1.5vw;
    text-align: left;
    color: #fff;
    font-size: 0.7vw;
    line-height: 2.5vh;
    .sq-s1 {
      font-size: 1.2vw;
      font-family: hy;
      color: rgba(255, 217, 1, 1);
      font-weight: bold;
      font-style: italic;
    }
  }
}
</style>
