d<template>
  <div class="numTb-box3">
    <!-- <div class="numTb-1">数量同比</div> -->
    <div class="left">
      <div class="top">累 <br /> 计<br /> 办<br /> 理 <br /> (人)</div>
      <div class="bottom">
          年 <br /> 度<br /> 办<br /> 理<br /> 
            <!-- 2 -->
          <div class="re">2020</div><br /> 年 <br /> (人)
      </div>
    </div>
    <div class="numTb-2" id="zhuzhuang"></div>
    <!-- <div class="numTb-3" id="zhexian"></div> -->
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  data() {
    return {
        indexUnit: ["人","人","人","对","对","对","对","具","人","人","人","人","人","人","对","对","对","具","人","人"],  // 单位转换
        ydata1: [],
        ydata2: [],
        xdata: ["居住证\n办理","敬老\n优待卡","社保卡\n办理","流动人口\n生育险办理","再生育\n办理","一二孩\n生育登记","户籍居民\n婚前检查","各类证明","申请独生子女\n父母奖励补贴","申办\n残疾证","失业\n登记","工资\n差额补贴","东城街道\n65周岁以上\n老人生活津贴","东莞市\n高龄津贴","东城街道\n大病救济 ","东城街道\n临时救济","东莞市\n重度残疾人\n护理补贴申请","东莞市\n退伍军人\n现役军人\n家属信息登记","从未参加\n社会养老\n补贴\n(民政)","东莞市\n困境儿童\n信息登记"],
        dataOptions: [],
        queryParams: {
            dataModule: "sftj",
            dataTyp: "sftj_xjly",
        },
        queryParams1: {
            dataModule: "sqdw",
            dataTyp: "sqdw_sqfwgl",
        },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams1).then((response) => {
        console.log("折线图数据",response);
        this.dataOptions = response.rows
        let xIndexIds1 = [132,336,134,340,338,339,341,342,343,344,347,348,349,350,351,352,354,355,358,359]
        let xIndexIds2 = [385,361,386,365,363,364,366,367,368,369,372,373,374,375,376,378,379,380,383,384]
        for(let i = 0; i < xIndexIds1.length; i++){
            this.ydata1.push(this.getdatavalue(xIndexIds1[i]))
            this.ydata2.push(this.getdatavalue(xIndexIds2[i]))
        }
        this.zhuzhuangT();
        console.log(this.ydata1);
        console.log(this.ydata2);
        // console.log((336));
        // var actions = '';
        // Object.keys(datas).some((key) => {
        //     if (datas[key].dataId == ('' + value)) {
        //     actions = datas[key].dataDetailed;
        //     // alert(actions)
        //     //	return true;
        //     return actions;
        //     }
        // })
        // return actions;
        // let ydata1 = this.dataOptions.map((item,index)=>{
        //     if(xIndexIds1.indexOf(item.dataId) != -1){
        //         return item.dataDetailed
        //     }
        // })
        // let ydata2 = this.dataOptions.map((item,index)=>{
        //     if(xIndexIds2.indexOf(item.dataId) != -1){
        //         return item.dataDetailed
        //     }
        // })
        // console.log(ydata1.filter(item=>item));
        // console.log(ydata2.filter(item=>item));
        // // console.log(this.dataOptions);
        // this.ydata1 = ydata1.filter(item=>item)
        // this.ydata2 = ydata2.filter(item=>item)
    });
  },

  methods: {
    // getvalue(datas,value) {
    //     var actions = '';
    //     Object.keys(datas).some((key) => {
    //         if (datas[key].dataId == ('' + value)) {
    //         actions = datas[key].dataDetailed;
    //         // alert(actions)
    //         //	return true;
    //         return actions;
    //         }
    //     })
    //     return actions;
    // },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 窗口政务服务事项
    zhuzhuangT() {
        var chartDom = document.getElementById('zhuzhuang');
        var myChart = this.$echarts.init(chartDom);
        var option;
        var _this = this
        option = {
        // title: {
        //     text: '雨量流量关系图',
        //     subtext: '数据来自西安兰特水电测控技术有限公司',
        //     left: 'center'
        // },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: false
            },
            formatter(e){
                // console.log(e[0].dataIndex)
                let index = e[0].dataIndex
                return `<div style="color:#04f3ee;white-space: pre">${e[0].axisValue}</div>
                        <div style="background: #FFF;height:1px"></div>
                        <div style="color:#04f3ee;">累计办理：${e[0].value}人</div>
                        <div style="color:#04f3ee;">年度办理：${e[1].value}人</div>`
            }
        },
        legend: {
            data: ['流量', '降雨量'],
            left: 10
        },
        // toolbox: {
        //     feature: {
        //         dataZoom: {
        //             yAxisIndex: 'none'
        //         },
        //         restore: {},
        //         saveAsImage: {}
        //     }
        // },
        axisPointer: {
            link: {xAxisIndex: 'all'}
        },
        // dataZoom: [
        //     {
        //         show: true,
        //         realtime: true,
        //         // start: 30,
        //         // end: 70,
        //         xAxisIndex: [0, 1]
        //     },
        //     {
        //         type: 'inside',
        //         realtime: true,
        //         // start: 30,
        //         // end: 70,
        //         xAxisIndex: [0, 1]
        //     }
        // ],
        grid: [{
            left: 0,
            top: 20,
            right: 10,
            height: '40%'
        }, {
            left: 0,
            right: 10,
            bottom: 10,
            top: '60%',
            height: '37%'
        }],
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,  // 表示从非零刻度开始
                axisLine: {onZero: true},
                data: this.xdata, //["居住证办理","非莞籍敬老优待卡","莞籍敬老优待卡","社保卡办理","生育险办理","再生育办理","一二孩生育登记","流动人口生育险","户籍居民婚前检查","各类证明","申办残疾证","残疾人\n公交爱心\nIC卡","申请残疾津贴","失业登记","工资差额补贴","东莞市高龄津贴","东城街道大病救济 ","东城街道临时救助 ","东莞市居家养老申请","东莞市困境儿童信息登记"],
                axisLabel: {
                        interval: 0,  //    强制性显示文字
                        textStyle: {
                            
                        },
                        fontSize:11,
                        // margin: 10, //刻度标签与轴线之间的距离
                        // formatter : function(params){
                        //         var newParamsName = "";// 最终拼接成的字符串
                        //         var paramsNameNumber = params.length;// 实际标签的个数
                        //         var provideNumber = 4;// 每行能显示的字的个数
                        //         var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                        //         /**
                        //         * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                        //         */
                        //         // 条件等同于rowNumber>1
                        //         if (paramsNameNumber > provideNumber) {
                        //             /** 循环每一行,p表示行 */
                        //             for (var p = 0; p < rowNumber; p++) {
                        //                 var tempStr = "";// 表示每一次截取的字符串
                        //                 var start = p * provideNumber;// 开始截取的位置
                        //                 var end = start + provideNumber;// 结束截取的位置
                        //                 // 此处特殊处理最后一行的索引值
                        //                 if (p == rowNumber - 1) {
                        //                     // 最后一次不换行
                        //                     tempStr = params.substring(start, paramsNameNumber);
                        //                 } else {
                        //                     // 每一次拼接字符串并换行
                        //                     tempStr = params.substring(start, end) + "\n";
                        //                 }
                        //                 newParamsName += tempStr;// 最终拼成的字符串
                        //             }
                        //         } else {
                        //             // 将旧标签的值赋给新标签
                        //             newParamsName = params;
                        //         }
                        //         //将最终的字符串返回
                        //         return newParamsName
                        // }
                },
                //设置轴线的属性
                axisLine: {
                    lineStyle: {
                        color: "#8FC9FE",
                        width: 1, //这里是为了突出显示加上的
                    }
                },
            },
            {
                gridIndex: 1,
                type: 'category',
                boundaryGap: true,  // 从非零刻度开始
                axisLine: {onZero: true},
                data: this.xdata, // ["居住证\n办理","敬老\n优待卡","社保卡\n办理","流动人口\n生育险办理","再生育\n办理","一二孩\n生育登记","户籍居民\n婚前检查","各类证明","申请独生子女\n父母奖励补贴","申办\n残疾证","失业\n登记","工资\n差额补贴","东城街道\n65周岁以上\n老人生活津贴","东莞市\n高龄津贴","东城街道\n大病救济 ","东城街道\n临时救济","东莞市\n重度残疾人\n护理补贴申请","东莞市\n退伍军人\n现役军人\n家属信息登记","从未参加\n社会养老\n补贴\n(民政)","东莞市\n困境儿童\n信息登记"],
                axisLabel: {
                    show:false,
                    // formatter: function(){
                    //     return "";
                    // }
                },
                position: 'top'  // X轴说明在上
            }
        ],
        yAxis: [
            {
                // name: '流量(m^3/s)',
                type: 'value',
                // max: 500,
                // name:"管\n线\n长\n度\n︵\n公\n里\n︶",
                // nameLocation:"center",
                // nameGap:35,
                // nameRotate:0,
                // nameTextStyle:{
                //     fontSize: 16,
                // },
                //默认以千分位显示，不想用的可以在这加一段
                axisLabel : {   //调整左侧Y轴刻度， 直接按对应数据显示
                    show:false,  
                    // showMinLabel:true,
                    // showMaxLabel:true,
                    // formatter: function (value) {
                    //     return '';
                    // }
                },
                splitLine:{
                    show:true,//不显示网格线
                    lineStyle:{
                        color: ["#028DD2"],
                        opacity: 0.22,
                        width: 1,
                        type: 'solid'
                    }
                },
            },
            {
                gridIndex: 1,
                // name: '降雨量(mm)',
                type: 'value',
                inverse: true,
                splitLine:{
                    show:true,//不显示网格线
                    lineStyle:{
                        color: ["#028DD2"],
                        opacity: 0.22,
                        width: 1,
                        type: 'solid'
                    }
                },
            }
        ],
        series: [
            {
                // name: '流量',
                type: 'bar',   // 柱状图
                symbolSize: 8,
                barWidth: "30%",
                hoverAnimation: false,
                data: _this.ydata1,
                itemStyle: {
                normal: {
                    label: {
                    show: true, //开启显示
                    position: "top", //在上方显示
                    // formatter(p){
                    //     // console.log(p);
                    //     return `${p.data}${_this.indexUnit[p.dataIndex]}`
                    // },
                    textStyle: {
                        //数值样式
                        color: "#fff",
                        fontSize: 14,
                    },
                    },
                    // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 1, color: "#1C2E4D" }, //柱图渐变色
                    { offset: 0.5, color: "#2363F1" }, //柱图渐变色
                    { offset: 0, color: "#1F79CB" }, //柱图渐变色
                    ]),
                },
                },
            },
            {
                // name: '日办理',
                type: 'line',
                xAxisIndex: 1,
                yAxisIndex: 1,
                symbol:'circle',//拐点设置为实心
                symbolSize: 8,  // 拐点圆点大小
                hoverAnimation: true,
                data: _this.ydata2,
                itemStyle: {
                normal: {
                    label: {
                    show: true, //开启显示
                    position: "bottom", //在上方显示
                    // formatter(p){
                    //     // console.log(p);
                    //     return `${p.data}${_this.indexUnit[p.dataIndex]}`
                    // },
                    textStyle: {
                        //数值样式
                        color: "#FE8E43",
                        fontSize: 14,
                    },
                    },
                    // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //   { offset: 1, color: "#2363F1" }, //柱图渐变色
                    //   { offset: 0.5, color: "#2363F1" }, //柱图渐变色
                    //   { offset: 0, color: "#1F79CB" }, //柱图渐变色
                    // ]),
                    color: '#FE8E43'
                },
                },
                lineStyle:{
                    normal:{
                        color:'#fdfdfd',//折线的颜色
                        width:'1'//折线粗细
                    }
                },
            }
        ]
}

        option && myChart.setOption(option);
    },
    zhexianT(){
        var chartDom = document.getElementById('zhexian');
        var myChart = this.$echarts.init(chartDom);
        var option;
        option = {
            // 配置图表方位
            grid: {
                top: '10%',
                left: "0",
                right: "0",
                // bottom: "-33%",
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: ["居住证办理","非莞籍敬老优待卡","莞籍敬老优待卡","社保卡办理","生育险办理","再生育办理","一二孩生育登记","流动人口生育险","户籍居民婚前检查","各类证明","申办残疾证","残疾人公交爱心IC卡","申请残疾津贴","失业登记","工资差额补贴","东莞市高龄津贴","东城街道大病救济 ","东城街道临时救助 ","东莞市居家养老申请","东莞市困境儿童信息登记"],
                axisLine: {onZero: true},
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: function(){
                        return "";
                    }
                }
            },
            series: [{
                data: [-150, -230, -224, -218, -135, -147, -260],
                type: 'line'
            }]
        };
        option && myChart.setOption(option);
    }
  },
  mounted() {
    // 柱状图
    // this.zhuzhuangT();
    // 折线图
    // this.zhexianT();
  },
};
</script>

<style scoped lang="scss">
.numTb-box3 {
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: hidden;
//   border: 1px solid tan;
  .left {
    width: 3%;
    height: 100%;
    //   background: red;
    color: #1beef1;
    float: left;
    border-right: 1px solid #0079ff;
    //   z-index: 9;
    .top{
        height: 50%;
        // text-align: right;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.5;
        text-align: center;
    }
    .bottom{
        height: 50%;
        // text-align: right;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        line-height: 1.2;
        text-align: center;
        .re{
            // text-align: center;
            margin-top: 1vw;
            transform:rotate(90deg);
            -ms-transform:rotate(90deg); /* Internet Explorer 9*/
            -moz-transform:rotate(90deg); /* Firefox */
            -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
            -o-transform:rotate(90deg); /* Opera */
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
        }
    }
  }
  .numTb-2 {
    width: 97%;
    // height: 36vh;
    height: 100%;
    // border: 1px solid teal;
    float: left;
  }
  //   .numTb-3 {
  //     width: 97%;
  //     height: 18vh;
  //     border: 1px solid rgb(128, 15, 0);
  //     // background: red;
  //     float: left;
  //   }
}
</style>
